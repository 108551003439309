import { rid } from "../../models/orientdb/CommonTypes.bin";
import { User } from "../../models/orientdb/User.bin";
import { ref_BroadcastAreasId } from "../../models/orientdb/ref_BroadcastAreas.bin";
import { ref_SupportsId } from "../../models/orientdb/ref_Supports.bin";
import { IUpsertInfo } from "../../types";

export class ref_Publications implements IUpsertInfo {
    "@rid"?: rid;
    Active: boolean;
    Key: string;
    Start: Date;
    End: Date;
    Publication: Date;
    Dated: string;
    TechnicalClosure: Date;
    CommercialClosure: Date;
    Created_by?: User["@rid"];
	Created_at?: Date;
	Updated_by?: User["@rid"];
	Updated_at?: Date;
    Reference: string;
    Theme: string;
    BroadcastArea: ref_BroadcastAreasId;
    Support: ref_SupportsId;
    DataImported: { [key: string]: any }[];

    Source?: "src_CODIPRESSE" | "src_TSM";
}