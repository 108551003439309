import * as React from 'react'
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin'
import { eKPI, eKPIType, IModelInfo, IsNumber, lnk_HasPropertyTypeExtended, KPIsManagerCache, KPICategory } from 'hub-lib/models/KPIsManager.bin';
import { Grid, TextField } from '@material-ui/core';
import { MetricsTextfield } from './MetricsTextfield.bin';
import { AdwAutocomplete } from "adwone-lib/index";
import { Trad, TradProp, TradValue } from 'trad-lib';
import { SimpleDatePicker } from '../../../ConfigurableComponents/SimpleDatepicker.bin';
import { clone, GetHashCode, groupByTag, JSONEqualityComparer, propertyOf } from 'hub-lib/tools.bin';
import { useDispatch, useSelector } from 'react-redux';
import { GetEditorState, RootState, SetModel, SetModelSync } from '../../../../redux/store';
import { FilesPicker } from '../FilesPicker.bin';
import { ContainerComponent } from '../ContainerComponent';
import { WebsiteTextBox } from '../../Adwone-admin/Referential/WebsiteTextBox';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Files, ref_Visuals } from 'hub-lib/dto/client/ref_Attachments.bin';
import { UpdateOJDData } from 'hub-lib/business/ojd'
import { otherRid } from './GroupEditor';
import { IMediaModel, kpis } from 'hub-lib/types';
import { lnk_HasKPI } from 'hub-lib/models/orientdb/lnk_HasKPI.bin';
import { lnk_HasPropertyType } from 'hub-lib/models/orientdb/lnk_HasPropertyType.bin';

type ModelMetricsArgs = { categories: KPICategory[], title: boolean, model: "ref_Messages" | "ref_Campaigns", syncMetrics?: boolean }

export function ModelMetrics({ categories, title, model, syncMetrics }: ModelMetricsArgs) {

    const [loaded, setLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [modelInfos, setModelInfos] = React.useState<{ [key: string]: IModelInfo[] }>();
    const data = clone(useSelector((root: RootState) => GetEditorState(model, root).get(), JSONEqualityComparer));

    React.useEffect(() => {

        if (loading)
            return;

        setLoading(true);

        Promise.resolve().then(async () => {
            let modelProperties: lnk_HasPropertyTypeExtended[] = [];
            let _modelInfos = {};

            const kpiManager = KPIsManagerCache.GetInstance(model);
            if (data.Media) {
                modelProperties = await kpiManager.GetLnkHasProperties(data.Media);
                modelProperties = modelProperties?.filter(p => p.Tags?.some(t => categories.includes(t as KPICategory)));

                const ojdProps = modelProperties.find(p => p.Id == "OjdYear");
                if (ojdProps) {
                    const waves = (data.Support && data.BroadcastArea)
                        ? await Client.getWaves({ support: data.Support, broadcastArea: data.BroadcastArea }).catch(e => ([]))
                        : []
                    if (waves) {
                        ojdProps.ValueChoices = waves.map(w => ({ rid: w as string, value: w as string }));
                        ojdProps.Sort = ((a, b) => b.value.localeCompare(a.value));
                    }
                    onChangeWave(false);
                }
                const adCreationProp = modelProperties.find(p => p.Id == "AdCreation");
                if (adCreationProp) {
                    let visuals: ref_Visuals[] = [];
                    if (data["Campaign"])
                        visuals = await Client.searchVertexTyped(ref_Visuals, { Campaign: data["Campaign"], Active: true });
                    adCreationProp.ValueChoices = visuals.map(v => ({ rid: v['@rid'], value: v.Name }));

                    if (data.ModelProperties["AdCreationOther"])
                        adCreationProp.ValueChoices.push({ value: data?.ModelProperties["AdCreationOther"], rid: otherRid } as any);
                    if (visuals.length == 1 && !data?.ModelProperties["AdCreation"]) {
                        data.ModelProperties["AdCreation"] = visuals[0]['@rid'];
                        SetModelSync(model, data);
                    }
                }
            }

            for (const category of categories) {
                const infos = [
                    ...applyOrder(modelProperties?.filter(p => p.Tags?.includes(category))),
                    ...await kpiManager.GetCategoryLnkHasKPIs(category, data.Media)
                ];
                if (infos.length > 0)
                    _modelInfos[category] = infos;
            }

            setModelInfos(_modelInfos);
            setLoaded(true);
            setLoading(false);
        })
    }, [data.Support, data.BroadcastArea])


    const isInfoDisable = (info: IModelInfo) => {
        return [eKPI.Total, eKPI.GrossFormat, eKPI.GrossFormatRate, eKPI.GrossFormatValRate].includes(info.Name as any);
    }

    const applyOrder = (properties: lnk_HasPropertyTypeExtended[]): lnk_HasPropertyTypeExtended[] => {
        return [...properties.filter(p => p.Rank).sort((a, b) => a.Rank - b.Rank), ...properties.filter(p => !p.Rank)];
    }

    const onChangeWave = async (force: boolean) => {
        await UpdateOJDData(data, force);
        SetModelSync(model, data);
    }

    const getSortedValueChoices = (p: lnk_HasPropertyTypeExtended) => {
        return [...(p.ValueChoices ?? [])].sort(p.Sort ?? ((a, b) => a.value.localeCompare(b.value)));
    }

    const generateInfos = (name: string, infos: IModelInfo[]) => {

        const { FullWidth, TextEditor, Upload, Website } = groupByTag(infos);

        const GetInfoValue = (data: IMediaModel, info: IModelInfo) => {
            const GetKey = (kpi: eKPI): string => {
                return modelInfos[name].find(k => k.Name === kpi)?.Id;
            }

            if (IsNumber(info.ValueType)) {
                if (info.Name == eKPI.Total) {
                    const total: number = (((data.KPIs as any)[GetKey(eKPI.Paid)] ?? 0) + ((data.KPIs as any)[GetKey(eKPI.Free)]) ?? 0);
                    (data.KPIs as any)[GetKey(eKPI.Total)] = total;
                    return total;
                }
                else {
                    if (info["@class"] == lnk_HasKPI.name)
                        return data.KPIs?.[info.Id] ?? 0;
                    else if (info["@class"] == lnk_HasPropertyType.name)
                        return data.ModelProperties?.[info.Id] ?? 0;
                }
            }
            if (info.ValueType == eKPIType.Date || info.ValueType == eKPIType.DateTime)
                return data.ModelProperties?.[info.Id] ? new Date(data.ModelProperties?.[info.Id]) : null;
            if ((info as any).ValueChoices) {
                return (info as any).ValueChoices.find(v => v.rid == data.ModelProperties?.[info.Id]);
            }
            return data.ModelProperties?.[info.Id];
        }

        const getXs = (propKey) => FullWidth.includes(name) || FullWidth.includes(propKey)
            ? 12
            : 6;

        const getClassName = (propKey, pos: "left" | "right") => FullWidth.includes(name) || FullWidth.includes(propKey)
            ? "message_details_full"
            : (pos == 'right' ? "message_details_rightcombo" : "message_details_leftcombo")

        let currentPos: 'left' | 'right' = 'right';

        return (<React.Fragment key={`model-metrics-${name}`}>
            {title && <Grid item xs={12} className="adw-title">{Trad(name)}</Grid>}
            <Grid container className={title ? "block-container" : ""}>
                {infos?.map((p, idx) => {
                    if (IsNumber(p.ValueType)) {
                        if (p["@class"] == lnk_HasKPI.name && !data.KPIs.hasOwnProperty(p.Id))
                            (data.KPIs as any)[p.Id] = 0;
                        if (p["@class"] == lnk_HasPropertyType.name && !data.ModelProperties.hasOwnProperty(p.Id))
                            (data.ModelProperties as any)[p.Id] = 0;
                    }

                    const xs = getXs(p.Id)
                    if (xs == 12) currentPos = 'right';
                    else if (currentPos == 'left' && xs == 6) currentPos = 'right';
                    else currentPos = 'left';

                    return (<Grid key={p.Id} item xs={xs} className={getClassName(p.Id, currentPos)}>
                        <div style={{ width: "100%" }}>
                            {Upload.includes(p.Name) && <Grid key={p.Id} item xs={12} className={"message_details_full"}>
                                <ContainerComponent title={`${Trad(p.Name)} | ${Trad("files")}`} className="auto-height">
                                    <FilesPicker rid={data['@rid']}
                                        autoComplete
                                        attachments={data.Attachments?.[p.Name] ? [data.Attachments[p.Name] as ref_Files] : null}
                                        type={p.Name}
                                        onRemove={(attachmentId) => {
                                            data.Attachments[p.Name] = null;
                                            console.log("Attachments", data.Attachments);
                                            SetModelSync(model, data);
                                        }}
                                        onChange={(attachment) => {
                                            data.Attachments[p.Name] = attachment;
                                            console.log("Attachments", data.Attachments);
                                            SetModelSync(model, data);
                                        }} />
                                    {/*<FilesPicker rid={data['@rid']} autoComplete attachmentsIds={data["attachments"]} type={p.Name} onChange={(attachmentId, deleted) => {
                                        if (deleted) {
                                            const index = data["attachments"].indexOf(attachmentId);
                                            data["attachments"].splice(index, 1);
                                            if (data["attachments"].length == 0)
                                                data["attachments"] = null;
                                        } else {
                                            data["attachments"] = [...(data["attachments"] || []), attachmentId];
                                        }
                                        SetModelSync(model, data);
                                    }} />*/}
                                </ContainerComponent>
                            </Grid>}
                            {!(p as any).ValueChoices &&
                                (p.ValueType != eKPIType.Date && p.ValueType != eKPIType.DateTime &&
                                    (Website.includes(p.Name) ?
                                        <WebsiteTextBox title={`${Trad(p.Name)} | ${Trad("link")}`}
                                            value={data.ModelProperties[p.Id]}
                                            onChange={(v) => {
                                                data.ModelProperties[p.Id] = v;
                                                SetModelSync(model, data);
                                            }}
                                        />
                                        :
                                        <MetricsTextfield
                                            modelInfo={p}
                                            isTextEditor={TextEditor.includes(p.Name)}
                                            isDisable={() => isInfoDisable(p)}
                                            getValue={() => GetInfoValue(data, p)}
                                            onChange={value => {
                                                if (p["@class"] == lnk_HasKPI.name)
                                                    data.KPIs[p.Id] = value;
                                                else if (p["@class"] == lnk_HasPropertyType.name)
                                                    data.ModelProperties[p.Id] = value;
                                                syncMetrics ? SetModelSync(model, data) : SetModel(model, data)
                                            }} />
                                    )
                                ) ||
                                ((p.ValueType == eKPIType.Date || p.ValueType == eKPIType.DateTime) &&
                                    <div style={{ overflow: 'visible', width: "100%" }}>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <SimpleDatePicker
                                                label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.${p.Name}`, ref_Messages)}
                                                defaultValue={GetInfoValue(data, p)}
                                                resetDate={() => {
                                                    data.ModelProperties[p.Id] = null;
                                                    SetModelSync(model, data);
                                                }}
                                                onChange={(event) => {
                                                    data.ModelProperties[p.Id] = event.value;
                                                    SetModelSync(model, data);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {(p as lnk_HasPropertyTypeExtended).ValueChoices &&
                                <AdwAutocomplete
                                    key={`${data.ModelProperties?.[p.Id]}-${GetHashCode((p as any).ValueChoices)}`}
                                    options={getSortedValueChoices(p as lnk_HasPropertyTypeExtended)}
                                    value={GetInfoValue(data, p)}
                                    onChange={(event: any, value: any) => {
                                        if (!data.ModelProperties)
                                            data.ModelProperties = {};
                                        data.ModelProperties[p.Id] = value?.rid;
                                        if (p.Id == "AdCreation") {
                                            if (data.ModelProperties[p.Id] != "#-1:-1")
                                                data.ModelProperties["AdCreationOther"] = null;
                                            else
                                                data.ModelProperties["AdCreationOther"] = value?.value;
                                        }
                                        if (p.Id == "OjdYear") onChangeWave(true);
                                        else SetModelSync(model, data);
                                    }}
                                    getOptionLabel={(v => v["@class"] ? TradValue(v["@class"], "Name", v.value) : v.value)}
                                    renderInput={(params: any) => <TextField {...params} variant="outlined" label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.${p.Name}` as any, ref_Messages)} />} />
                            }
                        </div>
                    </Grid>
                    )
                })}
            </Grid>
        </React.Fragment>)
    }

    return (<>
        {loaded && Object.keys(modelInfos).map(name => generateInfos(name, modelInfos[name]))}
    </>)

}
