import { DialogProps, IconButton } from "@material-ui/core";
import { Badge } from "@progress/kendo-react-indicators";
import { GetSort } from "format-lib/index.bin";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { clearEmptyValues, duplicate, hasConfig, orderMetaDatas, toArray } from "hub-lib/tools.bin";
import * as React from "react";
import { Trad } from "trad-lib";
import { BreadcrumbsCustom } from "../BreadcrumbsCustom";
import { CustomButton } from "../ConfigurableComponents/CustomButton.bin";
import { eDialogMode, ConfirmContent, GenericDialog } from "../ConfigurableComponents/GenericDialog.bin";
import { AdwRow, getIcon } from "adwone-lib/index";
import { TooltipManager } from "../CustomTooltip";
import Loader from "../layout/Loader";
import { FiltersComponent, TPropsFiltersComponents, HideOptions, FiltersParamsValue } from "./DefaultFiltersComponent";
import { AdwTelerikGrid } from "./Generic/AdwTelerikGrid.bin";
import {
    BreadcrumbsCustomContainer,
    ConfigurationPanelContainer, SelectedItemsContainer, ToolbarAdw, ToolbarContainer
} from "./Generic/ToolbarAdw";
import { VertexGrid } from "./Generic/VertexGrid.bin";
import { ConfigurationPanel, ElementArg } from "./Messages/ConfigurationPanel";
import { logError } from "tools-lib";
import { CustomIconButton } from "./Generic/CustomIconButton";
import CsvFile from "../../utils/csv-file.bin";
import { Notify } from "../../utils/Notify.bin";
import { TypeOptions } from "react-toastify";
import { FiltersChangeTrigger } from "./Filters/FiltersChangeTrigger";
import { FilterStorage, GetLocalStorageFiltersChartObj, SetLocalStorageChartFilters } from "../../utils/localstorage.bin";
import { ClassProperty } from "hub-lib/models/VertexProperty.bin";
import { RootState, store } from "../../redux/store";
import { useSelector } from "react-redux";
import { selectItems } from "../../redux/gridSlice";

let timeout: any = null;

export type NotifyConfig = {
    message: string,
    type: TypeOptions
}

class TProps<TVertex> {
    onDialogOpened?: (item: TVertex, mode?: eDialogMode) => void;
    filtersInToolbar?: boolean;
    datesInToolbar?: boolean;
    gridProps?: Partial<AdwTelerikGrid<TVertex>["props"]>;
    defaultItem?: TVertex;
    objectPrototype: new () => TVertex;
    dialogContainerStyle?: React.CSSProperties;
    dialogProps?: Partial<DialogProps>;
    dialogContent?: ((item: TVertex, mode?: eDialogMode) => JSX.Element) | ((item: TVertex, mode?: eDialogMode) => JSX.Element)[];
    stepConfig?: {
        submitTitle?: string;
        validation?: (item: TVertex, mode?: eDialogMode, notifyConfig?: NotifyConfig) => Promise<boolean>;
        dialogProps?: Partial<DialogProps>;
    }[];
    submitDialog?: (item: TVertex, mode: eDialogMode) => Promise<TVertex>;
    submitDelete?: (ids: string[]) => Promise<void>;
    grid?: VertexGrid<TVertex>;
    customConfigurationPanel?: ElementArg[];
    configurationPanelElements?: (defaultElements: ElementArg[], grid: DefaultGrid<TVertex>) => ElementArg[];
    filtersOrder?: TPropsFiltersComponents<TVertex>["order"];
    filtersParams?: { [key in keyof Partial<TVertex>]: FiltersParamsValue };
    filtersHideOptions?: HideOptions;
    additionalBreadcrumb?: BreadcrumbsCustom["props"]["elements"];
}

class TState<TVertex extends IRid> {
    item: TVertex = undefined;
    modeleConfig = {};
    additionalConfig = {};
    defaultConfig?: Partial<TVertex> & { properties?: string[] } = {};
    metaDatas: ClassProperty[] = [];
    hasHierarchy: boolean = false;
    hasDate: boolean = false;
    dialogOpen: boolean = false;
    dialogRMOpen: boolean = false;
    mode: eDialogMode;
    telerikGrid: AdwTelerikGrid<TVertex>;
    totalItems: number = 0;
    isFetching: boolean = false;
    step: number = 0;
}

class DefaultGrid<TVertex> extends React.Component<TProps<TVertex>, TState<TVertex>> {
    localStorageKey: string;
    constructor(props: TProps<TVertex>) {
        super(props);
        const newstate = new TState<TVertex>();
        newstate.item = duplicate(props.defaultItem);
        this.state = newstate;
        this.localStorageKey = `${this.props.objectPrototype.name}-additionalConfig-defaultGrid`;
    }

    getConfig(metadatas: ClassProperty[]) {
        const { hasDate } = this.state;
        let config = {};
        const fromLocal = GetLocalStorageFiltersChartObj(this.localStorageKey) ?? {};
        for (const key of Object.keys(fromLocal)) {
            if (metadatas.find(m => m.name === key)) {
                config[key] = fromLocal[key];
            }
        }
        if (hasDate) {
            const dateFilters = FilterStorage.getAdvancedFilters();
            config = {
                ...config,
                Start: dateFilters?.Start,
                End: dateFilters?.End
            }
        }
        return config;
    }

    setConfig(config: any) {
        return SetLocalStorageChartFilters(this.localStorageKey, config);
    }

    async componentDidMount(): Promise<void> {
        const { objectPrototype } = this.props;
        const metaDatas = (await Client.getAllMetadata(objectPrototype.name)).data.results;
        const orderedMetaDatas = orderMetaDatas(metaDatas);

        this.setState({
            hasHierarchy: orderedMetaDatas.find(e => e === "Hierarchy") !== undefined,
            hasDate: orderedMetaDatas.find(e => e === "Dates") !== undefined,
        }, () => {
            this.setState({
                metaDatas,
                additionalConfig: this.getConfig(metaDatas),
                modeleConfig: FilterStorage.get()?.Filters,
            }, () => {
                this.setState({
                    defaultConfig: this.props.grid.props.vertexParams
                }, () => {
                    this.props.grid.props.vertexParams = this.generateConfig();
                });
            });
        })
    }

    componentDidUpdate(prevProps: Readonly<TProps<TVertex>>, prevState: Readonly<TState<TVertex>>, snapshot?: any): void {
        if (this.props.grid && this.props.grid !== prevProps.grid) {
            this.setState({
                defaultConfig: this.props.grid.props.vertexParams
            }, () => {
                this.props.grid.props.vertexParams = this.generateConfig();
            });
        }
    }

    toggle = (key: keyof TState<TVertex>) => this.setState({ [key]: !this.state[key] } as any);

    updateGrid = async (item: TVertex): Promise<void> => {
        const { grid } = this.props;
        grid.elementsToSelect = [item["@rid"]];
        await grid.UpdateRows();
    }

    changeStep = (step: number) => {
        this.setState({ step });
        this.forceUpdate();
    }

    isFirstStep = () => this.state.step === 0;
    isLastStep = (steps: any[]) => this.state.step + 1 === steps.length;

    generateConfig = () => {
        const { additionalConfig, modeleConfig, defaultConfig, hasHierarchy } = this.state;
        return clearEmptyValues({ ...duplicate(defaultConfig), ...(hasHierarchy ? duplicate(modeleConfig) : {}), ...duplicate(additionalConfig) });
    }

    onConfChange = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const { grid } = this.props;
            grid.props.vertexParams = this.generateConfig();
            grid.UpdateRows();
        }, 500);
    };

    render() {
        const { filtersInToolbar, datesInToolbar, defaultItem, filtersOrder, filtersParams, filtersHideOptions, objectPrototype, dialogContent, submitDialog, submitDelete, dialogContainerStyle, dialogProps, gridProps, grid, configurationPanelElements, customConfigurationPanel, stepConfig, onDialogOpened } =
            this.props;
        const { isFetching, item, dialogOpen, dialogRMOpen, totalItems, mode, additionalConfig, step, metaDatas } =
            this.state;

        const dialogContents = toArray(dialogContent);

        if (!grid) return <Loader />;

        const defaultConfigurationPanelElements: ElementArg[] = [{
            type: "component",
            component: <IconButton
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad(eDialogMode.create) })}
                disabled={!RightManager.hasRight(eFunctions[objectPrototype.name], eRights.create)}
                className="custom_btn_primary no-radius no-shadow icon-panel-item"
                onClick={() =>
                    this.setState({
                        dialogOpen: true,
                        item: duplicate(defaultItem),
                        mode: eDialogMode.create,
                    }, () => onDialogOpened?.(this.state.item, eDialogMode.create))} >
                {getIcon("plus")}
            </IconButton>
        }, {
            type: "icon",
            title: () => Trad("filters"),
            icon: "filterAlt",
            badge: hasConfig(grid.props.vertexParams, ["Start", "End", "Active", "properties"]) ? (
                <Badge cutoutBorder align={{ vertical: "top", horizontal: "start" }} />
            ) : null,
            element: <FiltersComponent
                objectType={objectPrototype.name}
                hideOptions={filtersHideOptions}
                order={filtersOrder}
                filtersParams={filtersParams}
                onConfChange={(newAdditionalConf) => {
                    this.setState({ additionalConfig: newAdditionalConf }, this.onConfChange);
                    this.setConfig(newAdditionalConf);
                }}
                additionalConfig={additionalConfig}
                metaDatas={metaDatas}
            />,
        }, {
            type: "icon",
            title: () => Trad("export"),
            icon: "download",
            disabled: !RightManager.hasRight(eFunctions[objectPrototype.name], eRights.export),
            element: <div className="view-icons" style={{ display: "flex" }}>
                <CustomIconButton
                    onMouseOver={e => TooltipManager.Push({ target: e.target, text: Trad("CSV") })}
                    onClick={() => grid.exportExcel("csv")}><CsvFile /></CustomIconButton>
            </div>
        }];

        return <div className="grid_container">
            <FiltersChangeTrigger modele onChange={(filters) => {
                this.setState({ modeleConfig: filters }, this.onConfChange)
            }} />
            <FiltersChangeTrigger onChange={(filters) => {
                const { hasDate } = this.state;
                if (hasDate) {
                    this.setState({ additionalConfig: { ...additionalConfig, Start: filters?.Start, End: filters?.End } }, this.onConfChange)
                }
            }} />
            <div className="grid_body">
                <CustomToolBar
                    filtersInToolbar={filtersInToolbar}
                    datesInToolbar={datesInToolbar}
                    totalItems={totalItems}
                    objectPrototype={objectPrototype}
                    elements={customConfigurationPanel ? customConfigurationPanel : configurationPanelElements
                        ? configurationPanelElements(defaultConfigurationPanelElements, this)
                        : defaultConfigurationPanelElements}
                    toggle={this.toggle}
                    additionalBreadcrumb={this.props.additionalBreadcrumb}
                />
                <AdwTelerikGrid
                    onRef={ref => this.setState({ telerikGrid: ref })}
                    grid={grid}
                    hideToolbar
                    onEdit={(row: AdwRow<TVertex>) =>
                        this.setState({
                            dialogOpen: true,
                            item: duplicate(row.dataItem),
                            mode: eDialogMode.modify,
                        }, () => onDialogOpened?.(this.state.item, eDialogMode.modify))
                    }
                    onDuplicate={(row: AdwRow<TVertex>) =>
                        this.setState({
                            dialogOpen: true,
                            item: duplicate({ ...row.dataItem, ["@rid"]: null }),
                            mode: eDialogMode.duplicate,
                        }, () => onDialogOpened?.(this.state.item, eDialogMode.duplicate))
                    }
                    sort={GetSort<TVertex>(objectPrototype)}
                    onRowInitialized={(rows) => this.setState({ totalItems: rows?.length })}
                    selectable
                    selectionChange={(rows) =>
                        store.dispatch(selectItems(rows.map((e) => e.dataItem["@rid"])))
                    }
                    isDeleteDisable={
                        !RightManager.hasRight(eFunctions[objectPrototype.name], eRights.delete)
                    }
                    isCopyDisable={
                        !RightManager.hasRight(eFunctions[objectPrototype.name], [
                            eRights.create,
                            eRights.update,
                        ])
                    }
                    commandCellArgs={{
                        isEditable: RightManager.hasRight(eFunctions[objectPrototype.name], eRights.update),
                    }}
                    {...gridProps}
                />
                <GenericDialog
                    open={dialogOpen}
                    disablePrimaryButton={isFetching}
                    id="custom_items_dialog"
                    dialogTitle={`${Trad(mode)} - ${Trad(objectPrototype.name)}${step > 0 && item?.["Name"] ? ` - ${item["Name"]}` : ""}`}
                    actions
                    beforeButton={!this.isFirstStep() ? <CustomButton
                        style={{ position: 'absolute', left: 18 }}
                        className="custom_btn_primary"
                        Label={Trad("back")}
                        onClick={() => this.changeStep(step - 1)}
                        startIcon={getIcon("arrow_back")}
                    /> : undefined}
                    dialogProps={{ maxWidth: "md", fullWidth: true, ...(stepConfig?.[step]?.dialogProps ?? dialogProps ?? {}) }}
                    dialogContainerStyle={{ overflow: "auto", ...(dialogContainerStyle ?? {}) }}
                    dialogContent={dialogContents?.[step]?.(item, mode)}
                    submitClass={!this.isLastStep(dialogContents) ? "custom_btn_primary" : undefined}
                    submitTitle={!this.isLastStep(dialogContents) ? stepConfig[step] ? stepConfig[step].submitTitle : Trad("next") : Trad(mode)}
                    submitAction={async () => {
                        if (!this.isLastStep(dialogContents)) {
                            let isValid = true;
                            let notifyConfig: NotifyConfig = {
                                message: Trad("error_business_missingproperties"),
                                type: "error"
                            }
                            if (stepConfig[step].validation) {
                                isValid = await stepConfig[step].validation(item, mode, notifyConfig);
                            }
                            if (!isValid) {
                                if (notifyConfig.message) {
                                    Notify(notifyConfig.message, notifyConfig.type);
                                }
                            } else {
                                this.changeStep(step + 1);
                            }
                        } else {
                            this.setState({ isFetching: true });
                            try {
                                let result: TVertex;
                                if (submitDialog) {
                                    result = await submitDialog(item, mode);
                                } else {
                                    switch (mode) {
                                        case eDialogMode.create:
                                        case eDialogMode.duplicate:
                                            result = (await Client.createVertex(objectPrototype.name, item)).data.results;
                                            break;
                                        case eDialogMode.modify:
                                            result = (await Client.updateVertex(objectPrototype.name, item)).data.results;
                                            break;
                                    }
                                }
                                await this.updateGrid(result);
                                this.setState({ dialogOpen: false, step: 0 });
                            } catch (error) {
                                console.log(error);
                                logError(error);
                            }
                            this.setState({ isFetching: false });
                        }
                    }}
                    cancelAction={() => this.setState({ "dialogOpen": false, step: 0 })}
                />
                <CustomGenericDialog
                    dialogRMOpen={dialogRMOpen}
                    submitDelete={submitDelete}
                    objectPrototype={objectPrototype}
                    grid={grid}
                    toggle={this.toggle}
                />
            </div>
        </div>
    }
}

function CustomToolBar({ filtersInToolbar, datesInToolbar, totalItems, objectPrototype, toggle, additionalBreadcrumb, elements }) {
    const selectedItems = useSelector((state: RootState) => state.grid?.selectedItems);

    return (
        <ToolbarAdw includeFilters={filtersInToolbar} includeDates={datesInToolbar} hasSelectedItems={selectedItems?.length > 0} count={totalItems}>

            <ToolbarContainer>
                {selectedItems?.length > 0 && (
                    <SelectedItemsContainer>
                        <div style={{ display: "inline" }}>
                            <CustomButton
                                Label={Trad("delete")}
                                style={{ float: "right" }}
                                className="custom_btn_danger"
                                disabled={!RightManager.hasRight(eFunctions[objectPrototype.name], eRights.delete)}
                                startIcon={getIcon("delete")}
                                onClick={() => toggle("dialogRMOpen")}
                            />
                        </div>
                    </SelectedItemsContainer>
                )}
                <BreadcrumbsCustomContainer>
                    <BreadcrumbsCustom
                        hasSelectedItems={selectedItems?.length > 0}
                        elements={[{ text: Trad("home"), href: "/" }, ...(additionalBreadcrumb ?? []), { text: Trad(objectPrototype.name) }]}
                    />
                </BreadcrumbsCustomContainer>
            </ToolbarContainer>

            <ConfigurationPanelContainer>
                <ConfigurationPanel
                    elements={elements} />
            </ConfigurationPanelContainer>

        </ToolbarAdw>
    );
}

function CustomGenericDialog({ dialogRMOpen, submitDelete, objectPrototype, grid, toggle }) {
    const selectedItems = useSelector((state: RootState) => state.grid?.selectedItems);

    return <GenericDialog
        open={dialogRMOpen}
        dialogTitle={Trad("confirmation")}
        actions
        submitClass={"custom_btn_danger"}
        submitTitle={Trad("yes")}
        startIcon={getIcon("delete")}
        submitAction={async () => {
            toggle("dialogRMOpen");
            if (submitDelete) {
                await submitDelete(selectedItems);
            } else {
                await Client.deleteVertex(objectPrototype.name, selectedItems as any);
            }
            store.dispatch(selectItems([]));
            grid.UpdateRows();
        }}
        cancelAction={() => toggle("dialogRMOpen")}>
        <p>{ConfirmContent(selectedItems)}</p>
    </GenericDialog>;
}

export default DefaultGrid;
