import * as React from 'react';

import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { AdwRow, ADWColumn } from 'adwone-lib/index';
import { GetOrder } from 'format-lib/index.bin';
import { Trad } from 'trad-lib';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { distinct } from 'hub-lib/tools.bin';
import { AdvertiserHierarchyFilter, Filter } from 'hub-lib/dto/client/ref_FilterConfigurations.bin';
import history from '../../../utils/history';
import { storeElements } from '../../../redux/storageReducer';
import { store } from '../../../redux/store';
import { setFilters } from '../../../redux/projectSlice';
import { ItemNameButtonCell } from '../Cells/ItemNameButtonCell';
import { DataGrid } from '../Generic/ModeleGrid/DataGrid';
import { ref_TableConfigurations } from 'hub-lib/dto/client/ref_TableConfigurations.bin';
import { GetDefaultColumnConfiguration } from '../Generic/ModeleGrid/MessagesGridCreator';
import { KPIsManager, KPIsManagerCache } from 'hub-lib/models/KPIsManager.bin';
import { Client } from 'hub-lib/client/client.bin';

const defaultVertexParams = {
    Active: true,
    /*computeBudget: true,
    getDepartments: true,
    getEstimates: true*/
};
/* Old Toolbar Campaign Grid
<ToolbarContainer>
{selectedItems?.length > 0 && (
    <SelectedItemsContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <GenericDialog
                open={this.state.rmDialog}
                dialogTitle={Trad('confirmation')}
                handleClose={() => this.setState({ rmDialog: false })}
                submitAction={() => {
                    this.removeCampaigns();
                    this.setState({ rmDialog: false, selectedItems: [] });
                }}
                cancelAction={() => this.setState({ rmDialog: false })}
                submitClass="custom_btn_danger"
                submitTitle={Trad('yes')}
                startIcon={getIcon('clear')}
                actions={true}>
                <>
                    <p>{ConfirmDeleteContent(this.state.selectedItems)}</p>
                    <p>{this.state.countMessages + ' message(s) lié(s)'}</p>
                    <p>{this.state.countMessagesEstimated + ' message(s) déversé(s)'}</p>
                </>
            </GenericDialog>
            <div className="margin-right">
                <CustomBadge
                    count={this.state.selectedItems.length}
                    icon={'campaigns'}
                    tradClassKey={ref_Campaigns.name}
                />
            </div>
            <Button startIcon={getIcon('go')} className="custom_btn_secondary_white margin-right"
                disabled={!RightManager.hasRight(eFunctions.ref_Messages, eRights.read)} onClick={() => ShowMessages(selectedItems.map(i => i.dataItem))}>
                {Trad("see_messages")}
            </Button>
            {RightManager.hasRight(eFunctions.ref_Campaigns, eRights.delete) && <CustomButton
                Label={Trad('delete')}
                style={{ float: 'right' }}
                className="custom_btn_danger"
                startIcon={getIcon('delete')}
                onClick={() =>
                    this.setState({
                        rmDialog: true
                    }, async () => await this.getCountMessages())
                }
            />}
        </div>
    </SelectedItemsContainer>
)}
<BreadcrumbsCustomContainer>
    <BreadcrumbsCustom
        hasSelectedItems={selectedItems?.length > 0}
        elements={[{ text: Trad('home'), href: '/' }, { text: Trad('campaigns') }]}
    />
</BreadcrumbsCustomContainer>
</ToolbarContainer>
*/

export async function CreateCampaignDataGrid(filters: Partial<Filter>): Promise<DataGrid<ref_Campaigns>> {

    let columns: ADWColumn<ref_Campaigns>[] = [];
    let hiddenProperties: string[] = [];

    const nameColumn = new ADWColumn<ref_Campaigns>(Trad('Name'), 'Name', ePropType.String, true);
    nameColumn.autoTooltip = false;
    nameColumn.cellContent = ({ Formated, Value }, row: AdwRow<any>) => {
        const minEndDate = row.dataItem?.dataItem?.KPIs?.MinEndDate;
        const maxStartDate = row.dataItem?.dataItem?.KPIs?.MaxStartDate;
        const { Start, End } = store.getState().project?.filters;
        let incompletCampaign = null;
        if (Start && End)
            if (new Date(Start).getTime() > new Date(minEndDate).getTime() || new Date(End).getTime() < new Date(maxStartDate).getTime())
                incompletCampaign = "incomplet_campaign_message_info";
        return <ItemNameButtonCell value={Formated} warningMessage={incompletCampaign} onClick={() => ShowMessages([row.dataItem])} />;
    };
    nameColumn.width = 300;
    hiddenProperties.push('Name');
    columns.push(nameColumn);

    const configuration: ref_TableConfigurations = await GetDefaultColumnConfiguration(ref_Campaigns.name);
    const grid = new DataGrid<ref_Campaigns>({
        bodyParameters: { hideDetailsData: false },
        objectPrototype: ref_Campaigns,
        hiddenProperties,
        order: GetOrder<ref_Campaigns>(ref_Campaigns),
        width: {
            Start: 200,
            End: 200
        },
        vertexParams: {
            ...defaultVertexParams,
            ...filters
        },
        adapt: (rows: ref_Campaigns[]) => {
            storeElements(rows);
            return rows;
        },
        columns,
        configuration
    });
    return grid;
}

//const getCampaignWithBudget = (campaignRid: ref_Campaigns['@rid']) => Client.searchVertexTyped(ref_Campaigns, { '@rid': campaignRid, computeBudget: true }).then(res => res?.[0]);

export async function ShowMessages(campaigns: ref_Campaigns[]) {

    const { Start, End } = store.getState().project.filters;
    const newFilter = new Filter();
    newFilter.Start = Start;
    newFilter.End = End;
    newFilter.Source = distinct([...newFilter.Source, ...campaigns.map(c => c.Source)]);
    //newFilter.Source = distinct(campaigns.map(c => c.Source));
    newFilter.Campaign = campaigns.map(c => c['@rid']);

    const advHFilter = new AdvertiserHierarchyFilter();

    const campaignProperties = (await KPIsManagerCache.GetInstance(ref_Campaigns.name).GetUserProperties()).map(p => p.name);
    const hierarchyAdvProperties = KPIsManager.AdvertiserProperties.filter(p => campaignProperties.includes(p));

    if (hierarchyAdvProperties.length) {
        const campaigns = await Client.searchVertexTyped(ref_Campaigns, { "@rid": newFilter.Campaign, properties: hierarchyAdvProperties });
        for (const key of hierarchyAdvProperties) {
            if (!campaigns.every(c => Boolean(c[key]))) break;
            advHFilter[key] = distinct(campaigns.map(c => c[key]));
        }
    }

    const Name = Trad("default");
    history.push('/messages');
    store.dispatch(setFilters(({
        Filters: { ...newFilter, ...advHFilter },
        Default: true,
        Name,
        Active: true,
        Owner: null,
        Table: "Table"
    })));
}


