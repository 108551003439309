import { ADWColumn } from "adwone-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_Offers } from "hub-lib/dto/client/ref_Offers.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import * as React from "react";
import { Trad, TradProp } from "trad-lib";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import Template from "../Template";

export function OffersGridComponent() {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {

            const formatCol = new ADWColumn<ref_Offers>(TradProp("Format", ref_Messages), "ModelProperties.Format", ePropType.Link);
            formatCol.cellValue = (value, dataItem) =>
                dataItem.dataItem?.["FormatName"] ?? "";

            const placementCol = new ADWColumn<ref_Offers>(TradProp("Emplacement", ref_Messages), "ModelProperties.Emplacement", ePropType.Link);
            placementCol.cellValue = (value, dataItem) =>
                dataItem.dataItem?.["EmplacementName"] ?? "";

            const columns: ADWColumn<ref_Offers>[] = [
                new ADWColumn(TradProp("Updated_at"), "Updated_at", ePropType.Datetime),
                new ADWColumn(TradProp("Created_at"), "Created_at", ePropType.Datetime),
                formatCol,
                placementCol,
                new ADWColumn(TradProp("KPIs.Gross", ref_Messages), "KPIs.Gross", ePropType.Double)
            ];
            const hiddenProperties: (keyof ref_Offers)[] = ["Name", "Key", "DataImported", "Model"];
            //const properties: string[] = [];
            const grid = new VertexGrid<ref_Offers>({
                disableStore: true,
                objectPrototype: ref_Offers,
                devMode: false,
                columns,
                vertexParams: {
                    Active: true,
                    properties: ["*", "BroadcastArea.Name as BroadcastAreaName",
                        "ModelProperties.Format.Name as FormatName",
                        "ModelProperties.Emplacement.Name as EmplacementName"]
                },
                width: {
                    Currency: 120,
                    Media: 110,
                    Start: 110,
                    End: 110
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return <DefaultGrid
        objectPrototype={ref_Offers}
        grid={grid}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
}

export const OffersGrid = Template(OffersGridComponent);