
import { ADWGrid, ADWColumn, AdwRow, eRowEvent, eComputeEvent } from "adwone-lib/index";
import { Client } from "hub-lib/client/client.bin";
import { Trad, TradProvider, TradValue } from "trad-lib";
import { Format, GetCellTemplate, GetKPITemplate } from "format-lib/index.bin";
import { ClassProperty, ePropType, IsLink } from "hub-lib/models/VertexProperty.bin";
import { PropertyName, eColumnType } from "hub-lib/models/types.bin";
import { PropertiesProvider } from "./PropertiesPovider.bin";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { ConsoleDebug, IsDebugMode } from "../../../utils/localstorage.bin";
import { eKPIType, ToEPropType } from "hub-lib/models/KPIsManager.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { TableExport } from "hub-lib/models/external.bin";
import { Notify } from "../../../utils/Notify.bin";
import { CreateIndicateur, eIndicateurType, IndicateurToString, propertyOption } from "adwone-engine/index.bin";
import { AggregatorFactory } from 'hub-lib/aggregator/AggregatorFactory'
import { ExportArg } from "hub-lib/dto/client/ref_ExportConfigurations.bin";
import { IsIDDB, clone, GetSubElement } from "hub-lib/tools.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { CellValue } from "adwone-engine/types.bin";
import { EngineTools, FormatCellsMemoized } from "adwone-engine/EngineTools";
import { User } from "hub-lib/models/orientdb/User.bin";
import { store } from "../../../redux/store";
import { AddDevModeColumns } from "./AddDevModeColumns";
import { setTable } from "../../../redux/gridConfigurationsSlice";

let widths = {
    [ePropType.Date]: 155,
    [ePropType.Datetime]: 155,
}

export interface IPropertiesProvider {
    Provide(type: string): Promise<Property[]>;
}

export type props<TVertex> = {
    disableStore?: boolean,
    objectPrototype: new () => TVertex,
    objectPrototypeSuffixe?: string,
    initializePrototype?: (prototype: TVertex) => void;
    validator?: (vertex: TVertex, errors: (keyof TVertex)[], row: AdwRow<TVertex>) => Promise<void>;
    devMode?: boolean,
    forcedColumns?: ADWColumn<TVertex>[],
    columns?: ADWColumn<TVertex>[],
    hiddenProperties?: string[],
    readOnlyProperties?: string[],
    rows?: any[],
    // notVertex?:boolean,
    customConfCol?: boolean,
    vertexParams?: any;
    titles?: { [prop: string]: string },
    order?: ((keyof TVertex) | string)[],
    customCellValue?: { [property in keyof TVertex]?: (value: any, dataItem?: any) => Promise<any>; }
    columnParams?: { [property in keyof TVertex]?: { [p in keyof ADWColumn<TVertex>]?: any } };
    filterRows?: (row: TVertex) => any;
    width?: { [prop in keyof TVertex]?: number | string },
    frozenLeft?: (keyof TVertex)[],
    propertiesProvider?: IPropertiesProvider;
    configuration?: ref_TableConfigurations;
    adapt?: (rows: TVertex[]) => any;
    columnDecorator?: { [key in eColumnType]?: (col: ADWColumn<TVertex>) => ADWColumn<TVertex> | Promise<ADWColumn<TVertex>> };
    rowToVertex?: (row: AdwRow<TVertex>, object: any) => any;
    afterSearch?: (verteces: TVertex[]) => TVertex[] | Promise<TVertex[]>;
    onInlineEdited?: (vertex: TVertex, row: AdwRow<TVertex>) => void;
    onInlineDeleted?: (verteces: any[], rows: AdwRow<TVertex>[]) => void;
    onInlineNew?: (vertex: any, row: AdwRow<TVertex>) => void;
    onUpdateStarting?: (vertex: TVertex, row: AdwRow<TVertex>) => void;
    emptyGrid?: boolean;
    computeCellValues?: boolean;
};

const dicoNotifColumnChanged = {};

export class GridBase<TVertex> extends ADWGrid<TVertex> {
    metadata: ClassProperty[];

    async InitStore(bindingPath: string) {
        return undefined;
    }

    AddForcedColumns(columns: ADWColumn<TVertex>[]) {
        if (this.props.forcedColumns?.length) {
            this.props.forcedColumns?.forEach(f => {
                columns.unshift(f);
            })

            if (this.props.order) {
                columns.forEach(c => c.indexOrder = this.props.order.indexOf(c.bindingPath as string));
                columns = columns.sort((a, b) => a.indexOrder - b.indexOrder);
            }
        }
    }

    hiddenProperties: string[] = [
        "Active"
    ];

    objectPrototype: new () => TVertex;
    properties: Property[];
    props: props<TVertex>;
    //vertexParamsSaved?: any;
    private devMode: boolean = false;
    private additionnalColumns: ADWColumn<TVertex>[];

    constructor(props: props<TVertex>) {
        super();
        this.objectPrototype = props.objectPrototype;
        this.devMode = props.devMode;
        this.additionnalColumns = props.columns;
        this.props = props;

        if (props.hiddenProperties)
            this.hiddenProperties = this.hiddenProperties.concat(props.hiddenProperties);

        this.InitializeComponent(() => {
            //this.vertexParamsSaved = duplicate(props.vertexParams);
        });
    }

    async create(row: AdwRow<TVertex>) {
        // nothing here, can be overriden if needed
        return true;
    }

    async update(row: AdwRow<TVertex>) {
        // nothing here, can be overriden if needed
        return true;
    }

    async delete(rows: AdwRow<TVertex>[]) {
        // nothing here, can be overriden if needed
        return true;
    }

    async validator(vertex: TVertex, errors: (keyof TVertex)[], row: AdwRow<TVertex>): Promise<void> {
        if (this.props.validator) {
            await this.props.validator(vertex, errors, row);
        }
    }

    rowToObjectAfter(object: any, row: AdwRow<TVertex>) {
        // nothing here, can be overriden if needed
    }

    async GetDefaultColumnConfiguration() {
        let finalConf: ref_TableConfigurations = null;
        /** Try to get a custom conf */
        // let userConfLocalSto = GetConfigurationTab(this.objectPrototype.name);
        // if (userConfLocalSto) {
        //     let customConf = confs?.find((v: ref_TableConfigurations) => v["@rid"] === userConfLocalSto);
        //     if (customConf)
        //         finalConf = customConf;
        // }

        const userConfLocalSto = clone(store.getState().columsConfigurations?.configurations?.[this.objectPrototype.name]?.table);
        if (userConfLocalSto && this.objectPrototype.name == userConfLocalSto.Table)
            finalConf = userConfLocalSto;
        else {
            const confs = (await Client.searchVertex(ref_TableConfigurations.name, { Table: this.objectPrototype.name, Path: window.location.pathname }))?.data?.results;
            /** Get default conf for this table */
            finalConf = confs?.find((v: ref_TableConfigurations) => v.Default);
        }

        /** Garde fou pour n'afficher que des colonnes valides en cas de changement de modele */
        if (finalConf) {
            const allIndicateurs = (await AggregatorFactory.GetInstance().Get(this.objectPrototype).Provide()).map(i => i.indicateur);
            const cpy = [...finalConf.Columns];
            finalConf.Columns = finalConf.Columns.filter(c => allIndicateurs.some(o => IndicateurToString(o) === IndicateurToString(c)));
            ConsoleDebug(`finalConf.Columns => filtered`, cpy, finalConf.Columns)

            /** Colonnes ignorées */
            if (!dicoNotifColumnChanged[finalConf.Name]) {
                const columnRemoved = cpy.filter(c => !finalConf.Columns.some(f => f === c) && c?.name);
                if (columnRemoved?.length) {
                    Notify(`${Trad("columns_changed")} ` + columnRemoved.map(c => c.name).join(", "), "warning");
                    dicoNotifColumnChanged[finalConf.Name] = true;
                }
            }

            await AggregatorFactory.GetInstance().Get(this.objectPrototype).UpdateIndicateursNames(finalConf.Columns, allIndicateurs);
        }

        /** Ce cas ne devrait normalement pas arriver car on est censé avoir un tableau par défaut */
        if (!finalConf) {
            finalConf = new ref_TableConfigurations();
            finalConf.Name = Trad("empty_conf");
            finalConf.Columns = [];
        }

        return finalConf;
    }

    InitializeComponent = async (_callback?: () => void) => {

        if (!this.props.configuration) {
            try {
                let finalConf = await this.GetDefaultColumnConfiguration();
                if (this.props.customConfCol) this.props.configuration = finalConf;
            } catch (error) {
                Notify(Trad('cannot_get_defaut_configuration'), "error");
                console.log('Cannot get default configuration.', error)
            }
        }
        await this.Initialize();
        _callback?.();
    }

    private getTitle = (propname: string) => {
        return this.props?.titles?.[propname] ?? TradProvider.GetInstance().Trad({ Type: "static", Class: this.objectPrototype.name, Property: propname });
    }

    Initialize() {
        return this.InitializeColumns().then(this.UpdateRows);
    }

    private InitPropertiesProvdier = () => {
        if (!this.props.propertiesProvider)
            this.props.propertiesProvider = new PropertiesProvider(() => this.props.configuration);
    }


    private ApplyWidth(column: ADWColumn<TVertex>) {
        const overrideWidth = (this.props.width as any)?.[column.baseColumn?.field ?? column.bindingPath];
        if (overrideWidth != undefined)
            column.width = overrideWidth;
    }

    private CreateColumnFromProperty = (p: Property) => {
        let column = new ADWColumn<TVertex>(this.getTitle(p.name), p.name as any, p.type, !this.props.readOnlyProperties?.includes(p.name));
        column.baseColumn.options = p.options;
        column.width = (widths as any)[p.type];

        if (this.props.frozenLeft?.includes(p.name as any))
            column.frozen = "left";

        this.ApplyWidth(column);

        if (IsLink(p.type)) {
            let customCell = (this.props?.customCellValue as any)?.[p.name];
            column.cellValue = customCell ?? ((value: any, row) => {

                if (!row?.dataItem)
                    return value;

                const propInfo = EngineTools.BuildLinkPropertyInfo(p.name, p.options);
                const formated = row.dataItem[propInfo.alias];

                if (p.options?.match)
                    value = GetSubElement(row.dataItem, p.name, p.options?.match);

                if (!Array.isArray(value))
                    value = [value];
                return value
                    .map((val: any) => {

                        // TRICK
                        if (typeof val == 'string' && p.options?.priorityToField && (!IsIDDB(val) || val == "#-1:-1"))
                            return GetSubElement(row.dataItem, p.options.priorityToField);

                        if (formated && !p.options?.match)
                            return typeof formated == "string" ? TradValue(p.linkedClass, propInfo.property as never, formated) : formated;

                        if (propInfo.fallbackAlias)
                            return row.dataItem[propInfo.fallbackAlias] ?? formated;

                        const storedElements = this.store[p.name];
                        if (storedElements === undefined && val) {
                            this.InitStore(p.name)
                                .then(values => {
                                    if (values?.find((v) => v["@rid"] === val))
                                        row?.event?.emit?.(eComputeEvent.forceUpdate, { field: p.name });
                                })
                            return "...";
                        }

                        const storedElement = storedElements?.find((v) => v["@rid"] === val);
                        const ret = storedElement ? Format(storedElement) : val;
                        return ret;
                    })
                    .join("|")
            });
        }

        let override: { [property in keyof ADWColumn<TVertex>]?: any } = (this.props.columnParams as any)?.[p.name];
        if (override) {
            Object.entries(override).forEach(e => {
                (column as any)[e[0]] = e[1];
            })
        }

        return column;
    }

    private GetAdditionnalColumns = () => {
        let toAdd: ADWColumn<TVertex>[] = [];
        if (this.additionnalColumns) {
            toAdd = [...this.additionnalColumns];

            if (this.props.configuration) {
                toAdd = this.props.configuration.Columns.map(c => toAdd.find((t: ADWColumn<TVertex>) => t.title === c.name || t.bindingPath === c.field)).filter(t => t);
                toAdd.forEach(column => {

                    if (this.props.frozenLeft?.includes(column.bindingPath as any))
                        column.frozen = "left";

                    let overrideWidth = (this.props.width as any)?.[column.bindingPath];
                    if (overrideWidth != undefined)
                        column.width = overrideWidth;
                })
            }
        }
        return toAdd;
    }

    private EnableDevMode = () => {
        if (!this.devMode)
            this.devMode = IsDebugMode();
    }

    currencies: ref_Currencies[];

    InitializeColumns = async () => {

        // const { configuration } = this.props;
        //this.props.frozenLeft = configuration ? configuration.Columns.slice(0, configuration.FrozenPosition ?? 0).map(c => c.field as any) : [];

        this.InitPropertiesProvdier();

        this.metadata = (await Client.getMetadata(this.objectPrototype.name, true))?.data?.results;
        this.properties = await this.props.propertiesProvider.Provide(this.objectPrototype.name);

        let defaultcolumns = this.properties
            ?.filter(p => !this.hiddenProperties.includes(p.name) && !p.name.startsWith('ModelProperties.'))
            .map(this.CreateColumnFromProperty).filter(c => c);

        const disableStore: string[] = [];

        if (this.props?.vertexParams?.properties?.length) {
            this.properties
                ?.filter(p => IsLink(p.type) && !this.hiddenProperties.includes(p.name))
                .forEach(p => {
                    disableStore.push(p.name);
                    EngineTools.BuildLinkPropertyParams(p.name, p.options).forEach(p => {
                        if (!this.props.vertexParams.properties.includes(p))
                            this.props.vertexParams.properties.push(p)
                    });
                })
        }

        if (!this.props.disableStore) {
            const propsLinks = this.properties
                ?.filter(p => IsLink(p.type) && !this.hiddenProperties.includes(p.name))
                .filter(p => !disableStore.includes(p.name));
            for (const p of propsLinks) {
                try {
                    const res = await Client.searchVertex(p.linkedClass);
                    this.store[p.name] = res?.data?.results;
                } catch (error) {
                    console.error(error)
                }
            }
        }

        let columns = defaultcolumns.concat(this.GetAdditionnalColumns());

        if (this.props.order) {
            columns.forEach(c => c.indexOrder = this.props.order.indexOf(c.bindingPath as string));
            columns = columns.sort((a, b) => a.indexOrder - b.indexOrder);
        }

        this.EnableDevMode();
        if (this.props?.configuration?.Columns?.length > 0) {

            const allIndicateurs = await AggregatorFactory.GetInstance().Get(ref_Messages).Provide();
            this.currencies = (await Client.get<ref_Currencies>(ref_Currencies)).data.results;
            let indicateursConf = this.props?.configuration?.Columns;

            const tmpCols: ADWColumn<TVertex>[] = [];
            // rétablir l'ordre
            for (const indiConf of indicateursConf) {

                const indiInstance = CreateIndicateur(indiConf);
                /** check les colonnes override renseignées dans le constructeur */
                /** On peut override le comportement des colonnes pour un field donné */
                if (indiConf.field && !indiConf.options?.['formater']) {

                    if (indiConf.valueType === eKPIType.Rid) {
                        const property = this.metadata.find(p => p.name == indiConf.field);
                        if (property?.linkedClass != User.name) {
                            const columnRid = this.CreateColumnFromProperty({
                                name: indiConf?.options?.["MetaData"]?.["name"] ?? indiConf.field,
                                type: ePropType.Link,
                                linkedClass: property?.linkedClass,
                                options: indiConf.options as any
                            });
                            columnRid.title = indiConf.name;
                            this.ApplyWidth(columnRid);
                            tmpCols.push(columnRid);
                            continue;
                        }
                    }

                    const columnBase = columns.find(col => col.bindingPath === indiConf.field);
                    if (columnBase) {
                        const cloneColunm = clone(columnBase);
                        cloneColunm.title = indiConf.name;
                        cloneColunm.baseColumn = indiConf;
                        this.ApplyWidth(cloneColunm);
                        tmpCols.push(cloneColunm);
                        continue;
                    }
                }

                const columnKpiOverride = columns.find(col => col.title === indiConf.name);

                let colKPI = new ADWColumn<TVertex>(
                    indiConf.name,
                    columnKpiOverride?.bindingPath ?? IndicateurToString(indiConf),
                    columnKpiOverride?.dataType ?? ToEPropType(indiConf.valueType));

                colKPI.baseColumn = indiConf;
                colKPI.isKPI = true;

                colKPI.getKPIValue = async (rowData: any) => {
                    let value = await indiInstance.Compute([rowData]);

                    // Temporaire, une meilleure solution plus centralisée devrait etre trouvée
                    if (indiInstance.valueType === eKPIType.Percent && (indiInstance.type === eIndicateurType.computed || indiInstance.type === eIndicateurType.discount))
                        value *= 100;

                    return value;
                    // old way return rowData.KPIs[colKPI.bindingPath];
                }

                colKPI.cellValue = async (cellValue: any, dataItem?: AdwRow<TVertex>) => {

                    if (colKPI.baseColumn) {
                        const ind = CreateIndicateur(colKPI.baseColumn);
                        const res = await ind.Compute([dataItem.dataItem] as any);
                        const cellVal: CellValue[] = [{ Value: res, IndicateurSignature: IndicateurToString(ind), Type: 'cell', Formated: '' }];
                        const [formatedCell] = await FormatCellsMemoized(ref_Messages.name, ind, cellVal);
                        //const [formatedCell] = await EngineTools.FormatCells(ref_Messages.name, ind, cellVal);
                        return formatedCell?.Formated ?? '';
                    }

                    if (columnKpiOverride) return columnKpiOverride.cellValue(cellValue, dataItem);
                    const value = await colKPI.getKPIValue(dataItem.dataItem);
                    return GetCellTemplate(colKPI.dataType)(value);
                }

                colKPI.getValue = async (rowData: any) => {
                    let val = await colKPI.getKPIValue(rowData);
                    if (!val) val = 0;

                    const template = GetKPITemplate(indiConf.valueType);
                    if ([eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(indiConf.valueType)) {
                        const currency = this.currencies.find(cur => cur["@rid"] === rowData.Currency);
                        if (currency)
                            return `${template(val)} ${currency.Code}`;
                    }

                    return template(val);
                }

                this.ApplyWidth(colKPI);

                /** Récupération du eColumnType */
                const found = allIndicateurs.find(i => IndicateurToString(i.indicateur) === IndicateurToString(indiConf));
                if (found) {
                    const decorator = this.props.columnDecorator?.[found.columnType];
                    if (decorator)
                        colKPI = await decorator(colKPI);
                }
                tmpCols.push(colKPI);
            }

            columns = [...tmpCols];
        }

        AddDevModeColumns(columns);
        this.AddForcedColumns(columns);

        const { configuration } = this.props;
        if (configuration) {
            columns.forEach(c => c.frozen = undefined);
            columns.slice(0, configuration.FrozenPosition ?? 0).forEach(c => c.frozen = "left");
        }

        this.Columns = columns;
    }

    UpdateRowsFromServer = async () => {
        const time1379 = new Date().getTime();
        const res = await Client.searchVertex(this.objectPrototype.name + (this.props.objectPrototypeSuffixe ?? ""), this.props?.vertexParams);
        const rows = res?.data?.results;
        const _time1379 = new Date().getTime();
        console.log(`[PERF] searchVertex Elapsed ${_time1379 - time1379}ms`);
        return rows;
    }

    lastRequestId: number;
    UpdateRows = async () => {

        this.onRowsChanged.emit(eRowEvent.loading);
        if (this.props.rows) {
            this.Rows = this.props.rows
            return
        }
        let requestId = Date.now();
        this.lastRequestId = requestId;
        let rows = [];

        if (!this.props.rows) {
            try {
                rows = await this.UpdateRowsFromServer();
                const time9883 = new Date().getTime();
                if (this.props.afterSearch)
                    rows = await this.props.afterSearch(rows);
                const _time9883 = new Date().getTime();
                ConsoleDebug(`[PERF] afterSearch Elapsed ${_time9883 - time9883}ms`);
            } catch (error) {
                Notify(Trad(`cannot_get_elements`), "error");
                console.error(error);
            }
        }

        if (this.lastRequestId != requestId)
            return;

        const time2512 = new Date().getTime();
        if (this.props.filterRows)
            rows = rows.filter(this.props.filterRows);
        const _time2512 = new Date().getTime();
        ConsoleDebug(`[PERF] filterRows Elapsed ${_time2512 - time2512}ms`);

        const time9789 = new Date().getTime();
        await Promise.resolve(this.props.adapt?.(rows));
        const _time9789 = new Date().getTime();
        ConsoleDebug(`[PERF] adapt Elapsed ${_time9789 - time9789}ms`);

        if (!this.props.emptyGrid)
            this.Rows = rows;
        else
            this.Rows = [];
    }

    FormaterStoredData(data: any): string {
        return Format(data);
    }

    createData(): TVertex {
        const object = new this.objectPrototype;
        if (this.props.initializePrototype) {
            this.props.initializePrototype(object);
        }
        return object;
    }

    exportExcel = (exportType: "csv" | "formated", args?: ExportArg) => {
        const exportColumns = this.Columns.map(c => c.baseColumn).filter(c => c);
        const arg: TableExport = {
            ...args,
            type: "table",
            document: this.objectPrototype.name,
            filter: {
                ...this.props.vertexParams, ...(args && { Start: args.Start, End: args.End })
            },
            columnsGeneration: exportColumns?.length > 0 ? "fromData" : "fromMetadata",
            columns: exportColumns
        }
        return Client.downloadExport(exportType, arg, Trad(this.objectPrototype.name));
    }

    protected rowToObject(row: AdwRow<TVertex>): TVertex {
        let newObj: any = {};

        this.properties?.forEach(p => {

            const oldValue = row.dataItem[p.name];
            const value = row[p.name];

            row.dataItem[p.name] = value;

            // Suppress aliases
            if (oldValue != value)
                this.props.vertexParams.properties?.forEach((prop: string) => {
                    const chunks = prop.split(" ");
                    if (chunks.length == 3 && chunks[1]?.toLocaleLowerCase() == "as" && chunks[0].split(".")[0] == p.name) {
                        console.log(`delete`, chunks[2], oldValue, value)
                        delete row.dataItem[chunks[2]];
                    }
                })

            // if (row.dataItem.hasOwnProperty(p.name + "Name"))
            //     delete row.dataItem[p.name + "Name"]
            newObj[p.name] = value;
        });

        this.rowToObjectAfter(newObj, row);
        this.props?.rowToVertex?.(row, newObj);
        return newObj;
    }

    /**
     * Reorder columns depending on UI changes
     * @param columnIds
     */
    async onReorder(columnIds: string[]) {

        const conf = this.props?.configuration;
        if (conf && !conf.Default) {
            conf.Columns = [...conf.Columns].sort((a, b) => {
                let i1 = columnIds.indexOf(IndicateurToString(a));
                let i2 = columnIds.indexOf(IndicateurToString(b));

                if (i1 > -1 && i2 > -1)
                    return i1 - i2;
                return 0;
            })

            store.dispatch(setTable(conf));
        }

        //await this.InitializeColumns();
        //super.onReorder(columnIds);
    }
}

export type Property = {
    name: PropertyName;
    type: ePropType;
    linkedClass?: string;
    options?: propertyOption;
};