
import { Client, ClientAxios, ClientHUB, redirectionFinder, URLAdwoneProvider, URLServerProvider } from "hub-lib/client/client.bin";
// import io from 'socket.io-client';
import version from './version';

import { eSocketCommand, eSocketMsg, SocketAction, SocketCommand, SocketMessage, TaskProgressCommand } from "hub-lib/models/socket.bin";
import { closeNotify, Notify } from "./utils/Notify.bin";
import { UserExtended } from "hub-lib/dto/referential/UserExtended.bin";

import { Trad as ref_Trad } from "hub-lib/dto/admin/Trad.bin";
import { RightManager } from "hub-lib/models/types/rights.bin";


import numbers from 'cldr-numbers-full/main/fr/numbers.json';
import caGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/fr/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/fr/timeZoneNames.json';

import numbersGB from 'cldr-numbers-full/main/en-GB/numbers.json';
import caGregorianGB from 'cldr-dates-full/main/en-GB/ca-gregorian.json';
import dateFieldsGB from 'cldr-dates-full/main/en-GB/dateFields.json';
import timeZoneNamesGB from 'cldr-dates-full/main/en-GB/timeZoneNames.json';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import { load } from '@progress/kendo-react-intl';
import { ImportsStatus } from "hub-lib/dto/admin/ref_Imports.bin";
import { duplicate, memoizeAsync, SetReleaseEventLoopOverride } from "hub-lib/tools.bin";

import { TooltipManager as TooltipManagerAdw } from "adwone-lib/index";
import { TooltipManager } from "./components/CustomTooltip";
import { DataProvider } from 'hub-lib/provider'

import { maskRole } from "hub-lib/business/rights/rights.bin";
import { DefaultProdURLServer, GetServerUrlFromFrontUrl } from "adw-url-provider";

load(
    likelySubtags,
    currencyData,
    weekData, numbers,
    caGregorian,
    dateFields,
    timeZoneNames,

    numbersGB,
    caGregorianGB,
    dateFieldsGB,
    timeZoneNamesGB
)

SetReleaseEventLoopOverride(() => { });

ClientAxios.interceptors.response.use((response) => {
    const respData = response.data;
    if (ErrorRedirection.IsErrorRedirection(respData))
        window.location.href = respData.url;
    return response;
});

TooltipManagerAdw.Push = (p) => TooltipManager.Push(p);

RightManager.GetUser = () => {
    return JSON.parse(localStorage.getItem('user')) as UserExtended;
}

RightManager.rightsProvider = () => {
    return (JSON.parse(localStorage.getItem('user')) as UserExtended)?.userPermissions
}

RightManager.roleProvider = () => {
    const user = JSON.parse(localStorage.getItem('user')) as UserExtended;

    const roleName = Object.keys(maskRole).find(key => maskRole[key] & user?.maskRights);
    return roleName as any;
}

const searchDecorator = (params: Partial<ref_Messages | ref_Campaigns>) => {
    if (params?.Group) {
        delete params.AdvertiserGroup;
        delete params.Advertiser;
        delete params.Brand;
        delete params.Product;
    }
    return params;
}

URLAdwoneProvider.provide = () => window.location.origin;

export const redirections = {
    // "/exemple/big/path": "url", // Full path
    // [ref_Trad.name]: { // Splited path
    //     "search": "https://server.adwone.com",
    // },
    // [ref_Trad.name]: DefaultProdURLServer,
}

URLServerProvider.provide = (path?: string) => {
    // Custom URL for path


    // full patch then splited path
    if (path) {
        const redirectionFind = redirections[path] || redirectionFinder(redirections, path.split("/").filter(x => x));
        if (redirectionFind) return redirectionFind;
    }

    return GetServerUrlFromFrontUrl(window?.origin);
}

DataProvider.search = async <Ref>(vertexName: string, params?: any, op?: any) => {
    const res = await Client.searchVertex(vertexName, { ...params, _operators: op });
    return res?.data?.results as Ref[];
}

let searchMemoized: <Ref>(...agrs: any[]) => Promise<Ref[]> = null;
DataProvider.searchMemoized = <Ref>(vertexName: string, params?: any, op?: any) => {
    if (!searchMemoized)
        searchMemoized = memoizeAsync(({ _v, _p, _o }) => DataProvider.search(_v, _p, _o));
    return searchMemoized({ _v: vertexName, _p: params, _o: op });
}

DataProvider.getMetadata = async (vertexName: string, all: boolean = false) => {
    return (await Client.getMetadata(vertexName, all))?.data?.results;
}

DataProvider.getOJDWaves = async (params) => {
    return (await Client.getWaves(params));
}

DataProvider.getOJD = async (params) => {
    return (await Client.getOjd(params));
}

DataProvider.getVwMMDiscountClasses = async () => {
    const sourceMM = GetSrcMMUser();
    if (!sourceMM) return [];

    // Get MM View
    const views = await Client.searchVertex<vw_mm_HasDiscountClass>(vw_mm_HasDiscountClass.name, {
        in: sourceMM["@rid"],
        properties: ['out.ExternalID as ExternalID', 'Referential']
    })

    return <any>views?.data?.results ?? [];

    // const sources = await DataProvider.search<src_MM>(src_MM.name, { properties: ['@rid'] });
    // if (sources.length != 1)
    //     throw new Error(`Cannot find MM Source`);

    // // Get MM View
    // const views = await DataProvider.search<vw_mm_HasDiscountClass>(vw_mm_HasDiscountClass.name, {
    //     Referential: intersection,
    //     in: sources[0]["@rid"],
    //     properties: ['out.ExternalID as ExternalID', 'Referential']
    // })

}

var focused = true;

window.onfocus = function () {
    focused = true;
};
window.onblur = function () {
    focused = false;
};

Client.notificationEvent.addListener(eSocketMsg.notification, (body: SocketMessage) => {
    if (focused || body.force)
        Notify(body.message, body.messageType);
});

import { store } from "./redux/store";
import { finishImports, refreshImports } from "./actions/importsActions";
import { GetCurrentLocale, SetCurrentLocale, Trad, TradProvider } from "trad-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { keyStoreTrads } from "./config.bin";
import { GetSrcMMUser, GetUser, IsDebugMode, MetadataStorage } from "./utils/localstorage.bin";
import { NotifyCrons } from "hub-lib/dto/admin/ref_Crons.bin";
import { eReportStatus, ref_Reports } from "hub-lib/dto/client/ref_Reports";
import { ErrorRedirection } from "hub-lib/errors/types";
import { vw_mm_HasDiscountClass } from "hub-lib/models/orientdb/vw_mm_HasDiscountClass.bin";

ClientHUB.AddSearchDecorator({
    vertex: ref_Messages.name,
    decorate: searchDecorator
})

ClientHUB.AddSearchDecorator({
    vertex: ref_Campaigns.name,
    decorate: searchDecorator
})

ClientHUB.AddSearchDecorator({
    vertex: ref_Agreements.name,
    decorate: searchDecorator
})

let count = 0;
const checkVersion = async () => {
    try {
        if (!GetUser()?.["@rid"]) return;
        const v = (await Client.getHubVersion()).data;
        if (version != v) {
            Notify(Trad("version_deprecated"), "warning");
            if (count % 4 == 0) // once every 20 minutes
                Client.log({ Category: "version deprecated", Action: version, Informations: version });
            count++;
        }
    } catch (error) {

    }
}

checkVersion();
setInterval(checkVersion, 1000 * 60 * 5); // 5 minutes

Client.notificationEvent.addListener(eSocketMsg.command, async (body: SocketCommand) => {

    switch (body.command) {

        case eSocketCommand.logVersionClient:
            await Client.log({ Category: "version", Action: version, Informations: version });
            break;

        case eSocketCommand.sendLocalstorage:
            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key != keyStoreTrads)
                    localStorageData[key] = localStorage.getItem(key);
            }
            await Client.log({ Category: "localstorage", Action: "get", Informations: localStorageData });
            break;
        case eSocketCommand.clearCacheSession:
            await Client.getUser(true);
            Notify(Trad("your_account_modified"), "warning");
            break;
        case eSocketCommand.progress:

            if (body.infos?.taskId) {
                const task = body.infos as TaskProgressCommand;
                Notify(task.message, "info", { progress: { Current: task.current, Total: task.total, taskId: task.taskId } });
                break;
            }

            if (body.infos?.cron) {
                const { cron, report } = body.infos as { cron: NotifyCrons, report: ref_Reports }
                if (report.Progress.Status === eReportStatus.DONE) {
                    if (cron.TaskParams.EstimateID) {
                        if (report.Errors?.length === report.Progress.Total) {
                            Notify(Trad("no_message_deversed"), "error");
                        } else if (cron.TaskParams.EstimateType !== "Delete") {
                            Notify(`${Trad("campaign_deversed")} ${report.Progress.Current - report.Errors?.length}/${report.Progress.Total}`, "success");
                        } else {
                            Notify(Trad("estimate_updated"), "success");
                        }
                    }
                    closeNotify();
                } else {
                    Notify(Trad(cron.TaskParams.NotificationMessage ?? "please_wait_sending"), "info", { progress: report.Progress });
                }
            }
            if (body.infos?.Report?.Status == ImportsStatus.IN_PROGRESS)
                store.dispatch(refreshImports(body.infos, body.forceUpdate))
            else if ([ImportsStatus.PENDING, ImportsStatus.FAILED, ImportsStatus.DONE, ImportsStatus.IMPORTING].includes(body.infos?.Report?.Status))
                store.dispatch(finishImports(body.infos))
            break;
        default:
            // unkwnown
            break;
    }
});

Client.notificationEvent.addListener(eSocketMsg.action, async (body: SocketAction) => {
    Notify(body.message, body?.options?.type ?? "warning", {
        action: {
            label: Trad(body.service),
            onclick: async () => {
                switch (body.service) {
                    case "create":
                        await Client.createVertex(body.vertex, body.body);
                        break;
                    case "update":
                        await Client.updateVertex(body.vertex, body.body);
                        break;
                    case "delete":
                        await Client.deleteVertex(body.vertex, body.body);
                        break;
                }
            }
        }
    });
});


// let socket: SocketIOClient.Socket = null;
// let loading = false;
// let reconnectTimeout = null;

// const consoleSocketLog = (...args) => {
//     if (IsDebugMode())
//         console.log('[Socket]', ...args);
// }

Promise.resolve().then(async () => {

    while (true) {
        try {
            if (GetUser()) {
                const res = await (await Client.Post("/notifications", {})).data;
                switch (res.status) {
                    case eSocketMsg.notification:
                        Client.notificationEvent.emit(eSocketMsg.notification, res.body);
                        break;
                    case eSocketMsg.command:
                        Client.notificationEvent.emit(eSocketMsg.command, res.body);
                        break;
                    case eSocketMsg.action:
                        Client.notificationEvent.emit(eSocketMsg.action, res.body);
                        break;
                }

                // socket.on(eSocketMsg.notification, (body: any) => {
                //                 consoleSocketLog('[Socket] notification', body);
                //                 Client.notificationEvent.emit(eSocketMsg.notification, body);
                //             });

                //             socket.on(eSocketMsg.command, (body: any) => {
                //                 consoleSocketLog('[Socket] command', body);
                //                 Client.notificationEvent.emit(eSocketMsg.command, body);
                //             });

                //             socket.on(eSocketMsg.action, (body: any) => {
                //                 Client.notificationEvent.emit(eSocketMsg.action, body);
                //             });


                continue;
            }
        } catch (error) {
            // wait
        }

        await new Promise(r => setTimeout(r, 30000));
    }

})

Client.onGetStatus.addListener("GET", async (user: UserExtended) => {
    // if (!socket && !loading) {
    //     try {
    //         loading = true;
    //         const connectSocket = async () => {

    //             consoleSocketLog('[Socket] connecting...');
    //             clearTimeout(reconnectTimeout);
    //             reconnectTimeout = null;

    //             let data = null;
    //             while (!data) {
    //                 try {
    //                     const resCookie = await Client.GetCookie();
    //                     data = resCookie?.data;
    //                 } catch (error) {
    //                     consoleSocketLog('[Socket] Cannot get cookie. Retrying in 5 seconds...')
    //                     await new Promise(r => setTimeout(r, 5000));
    //                 }
    //             }

    //             consoleSocketLog('[Socket] getting cookie=' + data);

    //             socket = io(URLServerProvider.provide());
    //             socket.on('connect', () => {
    //                 consoleSocketLog(`[Socket] connected socket=${Boolean(socket)} cookie=` + data);
    //                 socket?.emit('log', { user, idSession: data, version })
    //             });

    //             socket.on(eSocketMsg.notification, (body: any) => {
    //                 consoleSocketLog('[Socket] notification', body);
    //                 Client.notificationEvent.emit(eSocketMsg.notification, body);
    //             });

    //             socket.on(eSocketMsg.command, (body: any) => {
    //                 consoleSocketLog('[Socket] command', body);
    //                 Client.notificationEvent.emit(eSocketMsg.command, body);
    //             });

    //             socket.on(eSocketMsg.action, (body: any) => {
    //                 Client.notificationEvent.emit(eSocketMsg.action, body);
    //             });

    //             socket.on('disconnect', () => {
    //                 consoleSocketLog('[Socket] disconnected');
    //                 // try to reconnect every 5 seconds
    //                 //clearTimeout(reconnectTimeout);
    //                 //reconnectTimeout = setTimeout(() => connectSocket(), 5000);
    //             });

    //             socket.on('connect_error', (e: any) => {
    //                 //socket?.disconnect();
    //                 //socket = undefined;
    //                 consoleSocketLog('[Socket] connect_error', e);
    //                 // try to reconnect every 5 seconds
    //                 //clearTimeout(reconnectTimeout);
    //                 //reconnectTimeout = setTimeout(() => connectSocket(), 5000);
    //             });

    //             socket.on('reconnect', (attemptNumber) => {
    //                 consoleSocketLog('Reconnecté au serveur après ' + attemptNumber + ' tentatives');
    //             });

    //             socket.on('reconnect_attempt', (attemptNumber) => {
    //                 consoleSocketLog('Tentative de reconnexion ' + attemptNumber);
    //             });

    //             loading = false;
    //         }

    //         await connectSocket();
    //     } catch (error) {
    //         consoleSocketLog('[Socket] Cannot start socket.')
    //         consoleSocketLog('[Socket] error:', error)
    //         //Client.log("ErrorSocket", { Tag: "ErrorSocket", Message: "Cannot create socket", Error: error })
    //     }
    // }
});

Client.onGetStatus.addListener("DELETE", () => {
    // socket?.disconnect();
    // socket = undefined;
});

export async function initialize(): Promise<any> {
    await TradProvider.GetInstance().Init();
    await SetCurrentLocale(GetCurrentLocale());
}


